import "core-js/stable";
import "regenerator-runtime/runtime";

import Navi from './components/main-nav.js';

(function() {

    let ready = function() {
        // Handler when the DOM is fully loaded
        console.log("DOMReady");
    };
      
    if ( document.readyState === "complete" || (document.readyState !== "loading" && !document.documentElement.doScroll)) {
        ready();
    } else {
        document.addEventListener("DOMContentLoaded", ready);
    }

})();